.footer-wrapper {

    $footer-padding: 10px;

    footer {
        padding-bottom: $footer-padding;
        padding-top: $footer-padding;
        background-color: transparent;

    }

}
