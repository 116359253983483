.btn {

     &.btn-primary,&.btn-info {
          background: $teal;
          background: linear-gradient(to bottom right, lighten($teal, 25%), lighten($teal, 10%));
          border-color: lighten($teal, 15%);
     }

     &.btn-success {
          border-color: darken($green, 5%) !important;

     }

     &.btn-dark {
          background: $black;
     }

     min-width: 120px;


}
