.archk-internal-sidebar {

    ul {

        padding-left: 0;
        margin-left: 0;
        margin-bottom: 0;

        li {
            list-style: none;
            cursor: pointer;

            padding: 8px 10px;

            &.active {
                background-color: $green;
                color: $white;
            }
        }

    }

}