.product {

    display: inline-block;
    width: 100%;
    .product-image {
        border: solid 2px white;
        opacity: 0;
        max-width: 100%;
    }

    .product-image-wrapper {
        background-size: cover;
        background-position: top;
        height: 200px;
        width: 100%;
        transform: rotate(3deg);
        border: inset 3px $yellow;
        overflow: hidden;
    }

    .product-sold {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(255,255,255,.5);
        text-align: center;
        z-index: 2;
        color: $yellow;
        font-size: 46px;
        padding-top: 70px;

        span {
            transform: rotate(3deg);
            background: rgba(0,0,0,.8);
            display: block;
            width: 110%;
            margin-left: -5%;
        }
    }

}

.product-featured {
    
    .product {

        .product-image-wrapper {
            height: 400px;
        }

    }

}