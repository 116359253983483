
@if ($theme == dark) {
 

    .bg-white.text-dark, .bg-white .text-dark {
        color: $black !important;
    }

}


.text-gradient-success {
    color: $green;
    background: linear-gradient(to right, darken($green, 20%), $green);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-info {
    color: $green;
    background: linear-gradient(to right, darken($teal, 20%), $teal);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-warning {
    color: $green;
    background: linear-gradient(to right, darken($orange, 20%), $orange);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-gradient-danger {
    color: $green;
    background: linear-gradient(to right, darken($red, 20%), $red);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-info-original {
    color: $tealOriginal !important;
}

.overflow-text {
    margin-right: -150px;
}

.text-right .overflow-text {
    margin-left: -150px;
    margin-right: inherit
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'Open Sans', sans-serif;
}

b {
    font-weight: bold;
}

a {
    color: darken($body-color, 10%);
}

@media(max-width: 1000px) {
    .text-md-down-left {
        text-align: left !important;
    }
}
@media(max-width: 600px) {
    .display-2 {
        font-size: 2.25rem !important;
    }
}

// a.btn {
//     color: $white !important;
// }